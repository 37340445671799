import React from "react";

import { NavLink } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";

import { useTheme } from "@mui/material/styles";

import {
  limpiar_caracteres_especiales,
  componerRutaImagenes,
} from "funciones/util";
import { incrementarPeso } from "funciones/pesosLocales";

export default function LocalCard({ nombrePueblo, local, formatoGrid }) {
  const theme = useTheme();

  const onClickLocal = (event) => {
    incrementarPeso(local.codLocal);
  };

  return (
    <Card
      sx={{
        display: "flex",
        m: formatoGrid ? 0 : 1,
        backgroundColor: formatoGrid
          ? "transparent"
          : theme.palette.secondary.main,
      }}
      elevation={formatoGrid ? 0 : 2}
    >
      <CardActionArea
        onClick={onClickLocal}
        data-codlocal={local.codLocal}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: formatoGrid ? "center" : "flex-start",
          p: formatoGrid ? 0.5 : 1,
        }}
        component={NavLink}
        to={`/${limpiar_caracteres_especiales(
          nombrePueblo
        )}/${limpiar_caracteres_especiales(local.nombreLocal)}/${
          local.codLocal
        }`}
      >
        <CardMedia
          component="img"
          sx={{
            width: formatoGrid ? "100%" : "clamp(50px, 10vw, 65px)",
          }}
          image={`${componerRutaImagenes(
            local.icono
          )}?v=${new Date().toDateString()}`}
          alt={`Logo ${local.nombreLocal}`}
        />
        {!formatoGrid && (
          <CardContent>
            <Typography
              variant="h5"
              sx={{ fontSize: "clamp(1em, 3.5vw, 1.5em)" }}
            >
              {local.nombreLocal}
            </Typography>
          </CardContent>
        )}
      </CardActionArea>
    </Card>
  );
}
