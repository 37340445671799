import { configureStore } from "@reduxjs/toolkit";
import pueblosReducer from "./slices/pueblos/pueblosSlice";
import puebloReducer from "./slices/pueblo/puebloSlice";
import localReducer from "./slices/local/localSlice";
import tablonReducer from "./slices/tablon/tablonSlice";

export default configureStore({
  reducer: {
    pueblos: pueblosReducer,
    pueblo: puebloReducer,
    local: localReducer,
    tablon: tablonReducer,
  },
});
