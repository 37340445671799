import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { cargarPuebloAsync } from "slices/pueblo/puebloSlice";

import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import Skeleton from "@mui/material/Skeleton";
import { NavLink } from "react-router-dom";

import Header from "components/Header";
import ContenedorLocales from "components/ContenedorLocales";
import ContenedorLocalesSkeleton from "components/ContenedorLocalesSkeleton";

import { getLocalesOrdenados } from "funciones/pesosLocales";
import { getTitlePueblo } from "funciones/titles";

export default function Pueblo() {
  let { codPueblo } = useParams();
  let [formatoGrid, setFormatoGrid] = useState(true);

  const pueblo = useSelector((state) => state.pueblo.locales[codPueblo]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cargarPuebloAsync(codPueblo));
    document.title = getTitlePueblo(
      `${pueblo ? pueblo.pueblo.nombrePueblo : ""}`
    );
  }, [dispatch, codPueblo]);

  useEffect(() => {
    document.title = getTitlePueblo(
      `${pueblo ? pueblo.pueblo.nombrePueblo : ""}`
    );
  }, [pueblo]);

  function clickAction(e) {
    e.preventDefault();
    setFormatoGrid(!formatoGrid);
  }

  return (
    <React.Fragment>
      <Header
        url="/"
        texto={`glotón ${pueblo ? pueblo.pueblo.nombrePueblo : ""}`}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <IconButton onClick={clickAction} aria-label="grid">
            {formatoGrid ? (
              <TableRowsRoundedIcon color="actions" fontSize="large" />
            ) : (
              <GridViewRoundedIcon color="actions" fontSize="large" />
            )}
          </IconButton>
          <IconButton aria-label="tablon">
            <NavLink
              to="tablon"
              aria-label="tablon"
              sx={{ fontSize: "inherit" }}
            >
              <LocalOfferRoundedIcon color="actions" fontSize="large" />
            </NavLink>
          </IconButton>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          <Fab color="fab" aria-label="Grid" onClick={clickAction}>
            {formatoGrid ? (
              <TableRowsRoundedIcon color="actions" fontSize="large" />
            ) : (
              <GridViewRoundedIcon color="actions" fontSize="large" />
            )}
          </Fab>
          <Fab color="fab" aria-label="Tablon">
            <NavLink
              to="tablon"
              aria-label="tablon"
              sx={{ fontSize: "inherit" }}
            >
              <LocalOfferRoundedIcon color="actions" fontSize="large" />
            </NavLink>
          </Fab>
        </Stack>
      </Header>
      <Container maxWidth="md">
        {pueblo && (
          <React.Fragment>
            <Typography align="center" variant="h4" component="h1">
              Comida a domicilio o para llevar en {pueblo.pueblo.nombrePueblo}
            </Typography>
            <ContenedorLocales
              formatoGrid={formatoGrid}
              locales={getLocalesOrdenados(pueblo.locales)}
              nombrePueblo={pueblo.pueblo.nombrePueblo}
            />
          </React.Fragment>
        )}

        {!pueblo && (
          <React.Fragment>
            <Typography align="center" variant="h4" component="h1">
              <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
            </Typography>
            <ContenedorLocalesSkeleton formatoGrid={formatoGrid} />
          </React.Fragment>
        )}
      </Container>
    </React.Fragment>
  );
}
