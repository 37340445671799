import { createSlice } from "@reduxjs/toolkit";

import { getLocalByCod } from "services/glotonApi";

export const localSlice = createSlice({
  name: "local",
  initialState: {
    menus: {},
  },
  reducers: {
    cargarLocal: (state, action) => {
      state.menus[action.payload.local.codLocal] = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { cargarLocal } = localSlice.actions;

export const esLocalSinCargar = (codLocal) => (dispatch, getState) => {
  let stateActual = getState().local;
  let resultado = true;

  if (stateActual.menus[codLocal] !== undefined) {
    resultado = false;
  }

  return resultado;
};

export const cargarLocalAsync = (codLocal) => (dispatch) => {
  if (dispatch(esLocalSinCargar(codLocal))) {
    fetch(getLocalByCod(codLocal))
      .then((response) => response.json())
      .then((localJSON) => {
        dispatch(cargarLocal(localJSON));
      });
  }
};

export default localSlice.reducer;
