import React from "react";

import { NavLink } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";

import { useTheme } from "@mui/material/styles";

import { limpiar_caracteres_especiales } from "funciones/util";

export default function ListaPueblos({ pueblos }) {
  const theme = useTheme();

  let skeletonList = [1, 2, 3, 4, 5];

  let listaPueblos =
    pueblos && pueblos.length > 0
      ? pueblos.map(
          (pueblo) =>
            pueblo.disponibilidad !== "0" && (
              <ListItem key={pueblo.codPueblo} disablePadding>
                <ListItemButton
                  sx={{
                    backgroundColor: theme.palette.primary.dark,
                    mb: 2,
                    color: "#fff",
                  }}
                  component={NavLink}
                  to={`/${limpiar_caracteres_especiales(pueblo.nombrePueblo)}/${
                    pueblo.codPueblo
                  }`}
                >
                  <ListItemText align="center" primary={pueblo.nombrePueblo} />
                </ListItemButton>
              </ListItem>
            )
        )
      : skeletonList.map((item) => (
          <ListItem key={"skeleton-pueblo-" + item} disablePadding>
            <Skeleton
              variant="rounded"
              sx={{
                width: "100%",
                maxWidth: "100%",
                height: 45,
                backgroundColor: theme.palette.primary.dark,
                mb: 2,
              }}
            ></Skeleton>
          </ListItem>
        ));

  return <List>{listaPueblos}</List>;
}
