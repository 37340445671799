import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { useSelector, useDispatch } from "react-redux";
import { cargarPueblosAsync, selectPueblos } from "slices/pueblos/pueblosSlice";

import Header from "components/Header";
import ContenedorPueblos from "components/ContenedorPueblos";

import { HOME_TITLE } from "constantes/titles";
import "./pueblos.css";

export default function Pueblos() {
  const pueblos = useSelector(selectPueblos);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cargarPueblosAsync());
    document.title = HOME_TITLE;
  }, [dispatch]);

  return (
    <React.Fragment>
      <Header url="/" texto="glotón" />
      <Box className="main-content" sx={{ pb: 5, boxShadow: 3 }}>
        <ContenedorPueblos className="home" pueblos={pueblos} />
      </Box>
      <Container className="info-content" maxWidth="md">
        <Grid container spacing={2} sx={{ py: 6 }}>
          <Grid item xs={12} sm={8} sx={{ textAlign: "center" }}>
            <Typography variant="h5" component="h2">
              Con <i>"glotón"</i> siempre a mano, ahórrate buscar los folletos
              de tus locales favoritos por los cajones de casa.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} sx={{ textAlign: "center" }}>
            <Typography variant="body1" component="h4">
              También disponible para Android:
            </Typography>
            <a
              id="link-android"
              href="https://play.google.com/store/apps/developer?id=A1Servicios&amp;hl=es"
            >
              <img
                alt="Disponible en Google Play"
                src="https://play.google.com/intl/en_us/badges/images/generic/es_badge_web_generic.png"
              />
            </a>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
