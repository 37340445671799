import React from "react";

import {
  Container,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
} from "@mui/material";

import { componerRutaImagenes } from "funciones/util";

function TablonContainer({ tablon }) {
  const listaOfertas = tablon
    ? tablon.map((oferta) => (
        <React.Fragment key={oferta.idOferta}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar
                alt={`Local ${oferta.nombreLocal}`}
                src={componerRutaImagenes(
                  `${oferta.icono}?v=${new Date().toDateString()}`
                )}
              />
            </ListItemAvatar>
            <ListItemText
              primary={oferta.titulo}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {oferta.descripcion}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))
    : [];
  return (
    <React.Fragment>
      <Container maxWidth="sm">
        {" "}
        <List sx={{ width: "100%" }}>{listaOfertas}</List>
      </Container>
    </React.Fragment>
  );
}

export default TablonContainer;
