import React from "react";

import Grid from "@mui/material/Grid";

import LocalCard from "../LocalCard";

function ContenedorLocales({ locales, nombrePueblo, formatoGrid }) {
  let listaLocales = locales.map((local) => (
    <Grid item xs={formatoGrid ? 4 : 12} key={local.codLocal}>
      <LocalCard
        formatoGrid={formatoGrid}
        nombrePueblo={nombrePueblo}
        local={local}
      />
    </Grid>
  ));

  return <Grid container>{listaLocales}</Grid>;
}

export default ContenedorLocales;
