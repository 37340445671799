import { createSlice } from "@reduxjs/toolkit";

import { getPueblos } from "services/glotonApi";

export const pueblosSlice = createSlice({
  name: "pueblos",
  initialState: {
    pueblos: [],
  },
  reducers: {
    cargarPueblos: (state, action) => {
      state.pueblos = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { cargarPueblos } = pueblosSlice.actions;

export const estanPuebloSinCargar = () => (dispatch, getState) => {
  let stateActual = getState().pueblos;
  let resultado = true;

  if (stateActual.pueblos.length > 0) {
    resultado = false;
  }

  return resultado;
};

export const cargarPueblosAsync = () => (dispatch) => {
  if (dispatch(estanPuebloSinCargar())) {
    fetch(getPueblos())
      .then((response) => response.json())
      .then((pueblosJSON) => {
        dispatch(cargarPueblos(pueblosJSON.pueblos));
      });
  }
};

export default pueblosSlice.reducer;

export const selectPueblos = (state) => state.pueblos.pueblos;
