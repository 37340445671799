const idOrdenLocales = "orden-locales-gloton";

function isLocalStorageAvailable() {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

function incrementarPeso(codLocal) {
  let pesosLocales = getPesosLocales();

  pesosLocales[codLocal] = (pesosLocales[codLocal] || 0) + 1;
  if (isLocalStorageAvailable() === true) {
    localStorage.setItem(idOrdenLocales, JSON.stringify(pesosLocales));
  }
}

function getPesosLocales() {
  if (isLocalStorageAvailable() === true) {
    let pesosLocales = JSON.parse(localStorage.getItem(idOrdenLocales)) || {};
    return pesosLocales;
  }
}

function getLocalesOrdenados(locales) {
  let pesosLocales = getPesosLocales();
  let localesOrdenados = JSON.parse(JSON.stringify(locales));

  for (var i = 0; i < localesOrdenados.length; i++) {
    localesOrdenados[i].peso = parseInt(
      pesosLocales[localesOrdenados[i].codLocal] || 0
    );
  }

  localesOrdenados.sort(function (a, b) {
    if (a.peso > b.peso) return -1;
    if (a.peso < b.peso) return 1;
    return 0;
  });

  return localesOrdenados;
}

export { incrementarPeso, getLocalesOrdenados };
