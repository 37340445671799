import React from "react";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

import "./header.css";

import { NavLink } from "react-router-dom";

export default function Header({
  url,
  texto,
  retorno,
  logo,
  children,
  skeletonType,
}) {
  const theme = useTheme();
  let breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  let bloqueLogo = (
    <Stack direction="row" alignItems="center" spacing={1}>
      {retorno && <ArrowBackIosNewRoundedIcon fontSize="large" />}
      {logo && <Avatar alt={`Logo ${texto}`} src={logo} />}
      <strong>{texto}</strong>
    </Stack>
  );

  let bloqueLogoSkeletonLocal = (
    <Stack direction="row" alignItems="center" spacing={1}>
      {retorno && <ArrowBackIosNewRoundedIcon fontSize="large" color="white" />}
      <Skeleton variant="circular">
        <Avatar />
      </Skeleton>
      <Skeleton variant="text" width={300} height={51} />
    </Stack>
  );

  return (
    <AppBar color="primary" position="static">
      <Container maxWidth="md" disableGutters={breakpointDownSm}>
        <div className="logo">
          {skeletonType === undefined && (
            <NavLink to={url}>{bloqueLogo}</NavLink>
          )}
          {skeletonType === "local" && (
            <NavLink to={url}>{bloqueLogoSkeletonLocal}</NavLink>
          )}
        </div>
        <div className="actions-container">{children}</div>
      </Container>
    </AppBar>
  );
}
