import React from "react";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

function Plato({ plato, tema, conDivider }) {
  let {
    colorLetraTitulo,
    colorFondoTitulo,
    colorLetraNormal,
    colorFondoNormal,
  } = tema;
  let esTitulo = plato.precio === "-1";

  let estiloPlato = {
    px: 2,
    backgroundColor: esTitulo ? colorFondoTitulo : colorFondoNormal,
    color: esTitulo ? colorLetraTitulo : colorLetraNormal,
  };

  return (
    <Paper elevation={0} square>
      <Grid container sx={estiloPlato}>
        <Grid item xs={9} sx={{ fontWeight: "bold" }}>
          <p>{plato.nombre}</p>
        </Grid>
        {!esTitulo && (
          <Grid item xs={3} textAlign="right">
            <p>
              {plato.precio}
              {plato.precio.match(/^\d{1,3},\d{2}$/g) ? "€" : ""}
            </p>
          </Grid>
        )}
        {plato.descripcion.length > 0 && (
          <Grid item xs={12} sx={{ mb: 1 }}>
            <span>{plato.descripcion}</span>
          </Grid>
        )}
      </Grid>

      {conDivider && <Divider />}
    </Paper>
  );
}

export default Plato;
