import React from "react";
import Modal from "../Modal";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";

import { isMobile } from "react-device-detect";

function ModalTelefono({ open, onClose, telefonos, codLocal }) {
	const theme = useTheme();
	const telefonosUtiles = telefonos.filter((telefono) => telefono);

	const handleListItemClick = (value) => {
		onClose(value);

		if (isMobile) document.location.href = `tel:${value}`;
	};

	return (
		<Modal
			open={open}
			onClose={onClose}>
			<List>
				{telefonosUtiles.map((telefono, index) => (
					<ListItem
						onClick={() => handleListItemClick(telefono)}
						key={telefono}>
						<ListItemAvatar>
							<Avatar
								sx={{
									bgcolor: theme.palette.primary.light,
									color: theme.palette.primary.main,
								}}>
								<PhoneRoundedIcon />
							</Avatar>
						</ListItemAvatar>
						<ListItemText
							primary={`${
								codLocal === 76 && index === 1 ? "Incidencias: " : ""
							}${telefono}`}
						/>
					</ListItem>
				))}
			</List>
		</Modal>
	);
}

export default ModalTelefono;
