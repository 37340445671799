import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import ListaPueblos from "./ListaPueblos";

export default function ContenedorPueblos({ pueblos }) {
  return (
    <Container maxWidth="md">
      <Typography
        align="center"
        variant="h4"
        component="h1"
        sx={{
          color: "#fff",
          fontWeight: "bold",
          textShadow: "4px 2px 6px #000",
        }}
      >
        ¿De qué pueblo eres?
      </Typography>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={8} md={4}>
          <ListaPueblos pueblos={pueblos} />
        </Grid>
      </Grid>
    </Container>
  );
}
