import React from "react";
import Modal from "../Modal";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import MopedRoundedIcon from "@mui/icons-material/MopedRounded";
import LocalDiningRoundedIcon from "@mui/icons-material/LocalDiningRounded";
import { useTheme } from "@mui/material/styles";

function ModalInfo({ open, onClose, local, pueblo }) {
  const theme = useTheme();
  const title = "Información general";

  const getLiteralServicio = (tipoServicio) => {
    switch (tipoServicio) {
      case "1":
        return "Servicio a domicilio y recogida en local en la dirección:";

      case "2":
        return "Recogida en local en la dirección:";

      case "3":
        return "Para tomar en local en la dirección:";
      default:
        return "";
    }
  };

  return (
    <Modal open={open} onClose={onClose} title={title} maxWidth={"sm"}>
      <Box px={3} pb={3}>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          <AccessTimeRoundedIcon
            sx={{ verticalAlign: "sub", marginRight: 1 }}
          />
          Horario:
        </Typography>
        <Box p={1} bgcolor={theme.palette.secondary.light} mb={2}>
          {local.horario.split("<br/>").map((lineaHorario, index) => {
            return (
              <Typography
                variant="body1"
                key={`horario${local.codLocal}${index}`}
              >
                {lineaHorario}
              </Typography>
            );
          })}
        </Box>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          <MopedRoundedIcon sx={{ verticalAlign: "sub", marginRight: 1 }} />
          Coste del servicio:
        </Typography>
        <Box p={1} bgcolor={theme.palette.secondary.light} mb={2}>
          {local.costeServicio.split("<br/>").map((lineaCoste, index) => {
            return (
              <Typography
                variant="body1"
                key={`coste${local.codLocal}${index}`}
              >
                {lineaCoste}
              </Typography>
            );
          })}
        </Box>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          <LocalDiningRoundedIcon
            sx={{ verticalAlign: "sub", marginRight: 1 }}
          />
          Tipo de servicio:
        </Typography>
        <Box p={1} bgcolor={theme.palette.secondary.light} mb={1}>
          <Typography variant="body1" mb={1}>
            {getLiteralServicio(local.tipoServicio)}
          </Typography>
          <div>
            <span>{local.direccion}</span>
            <br />
            <span>{pueblo.nombrePueblo}</span>,<br />
            <span>
              {pueblo.nombreProvincia} {pueblo.codPostal}
            </span>
          </div>
        </Box>
      </Box>
    </Modal>
  );
}

export default ModalInfo;
