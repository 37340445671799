import { HOME_TITLE } from "constantes/titles";

function getTitlePueblo(pueblo) {
  return `${HOME_TITLE} ${pueblo}`;
}

function getTitleLocal(pueblo, local) {
  return `${HOME_TITLE} ${pueblo} ${local}`;
}

function getTitleTablon(pueblo) {
  return `${HOME_TITLE} Tablón de ${pueblo}`;
}

export { getTitlePueblo, getTitleLocal, getTitleTablon };
