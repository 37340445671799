import React from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

export default function ContenedorLocalesSkeleton({ formatoGrid }) {
  let getGridItemElement = (indexKey) => {
    return (
      <Grid key={indexKey} item xs={formatoGrid ? 4 : 12} sx={{ p: 1 }}>
        <Skeleton
          variant="rounded"
          sx={{
            width: formatoGrid ? "100%" : "auto",
            height: formatoGrid ? 0 : 65,
            pb: formatoGrid ? "100%" : 0,
          }}
        />
      </Grid>
    );
  };

  let gridItemSkeleton = () => {
    let skeletonGrid = [];
    for (let i = 0; i < 9; i++) {
      skeletonGrid.push(getGridItemElement("locales-skeleton-" + i));
    }
    return skeletonGrid.map((item) => item);
  };

  return <Grid container>{gridItemSkeleton()}</Grid>;
}
