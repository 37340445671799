import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useTheme } from "@mui/material/styles";

import Plato from "../Plato";

function Menu({ menu, platos, local }) {
  let numCategorias = platos[platos.length - 1].ordenCategoria;
  let arrayCategorias = [];

  let tema = {
    colorLetraTitulo: local.colorLetraTitulo,
    colorFondoTitulo: local.colorFondoTitulo,
    colorLetraNormal: local.colorLetraNormal,
    colorFondoNormal: local.colorFondoNormal,
  };

  for (let i = 0; i < numCategorias; i++) {
    arrayCategorias[i] = platos.filter(
      (plato) => parseInt(plato.ordenCategoria) === i + 1
    );
  }

  let listaCategorias = arrayCategorias.map((platosCategoria, indice) => {
    return <Categoria key={indice + 1} platos={platosCategoria} tema={tema} />;
  });

  return <React.Fragment>{listaCategorias}</React.Fragment>;
}

function Categoria({ platos, tema }) {
  let orden = platos[0].ordenCategoria;
  let nombre = platos[0].nombreCategoria;

  const theme = useTheme();

  let listaPlatos = platos.map((plato, index) => {
    let esUltimaPosicion = index >= platos.length - 1;

    return (
      <Plato
        key={plato.codPlato}
        plato={plato}
        tema={tema}
        conDivider={!esUltimaPosicion}
      />
    );
  });

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${orden}-header`}
        id={`panel${orden}-header`}
        sx={{
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>{nombre}</Typography>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          backgroundColor: tema.colorFondoNormal,
          p: 0,
        }}
      >
        {listaPlatos}
      </AccordionDetails>
    </Accordion>
  );
}

export default Menu;
