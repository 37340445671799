function limpiar_caracteres_especiales(cadena) {
  cadena = cadena.replaceAll("á", "a");
  cadena = cadena.replaceAll("Á", "A");
  cadena = cadena.replaceAll("Í", "I");
  cadena = cadena.replaceAll("í", "i");
  cadena = cadena.replaceAll("é", "e");
  cadena = cadena.replaceAll("É", "E");
  cadena = cadena.replaceAll("ó", "o");
  cadena = cadena.replaceAll("Ó", "O");
  cadena = cadena.replaceAll("ú", "u");
  cadena = cadena.replaceAll("Ú", "U");
  cadena = cadena.replaceAll("ç", "c");
  cadena = cadena.replaceAll("Ç", "C");
  cadena = cadena.replaceAll("ñ", "n");
  cadena = cadena.replaceAll("Ñ", "N");
  cadena = cadena.replaceAll(" ", "-");
  cadena = cadena.replaceAll("'", "");
  cadena = cadena.replaceAll("/", "");
  return cadena;
}

function componerRutaApi(path) {
  const RUTA_API = "https://www.glotoncomida.com/servicios/API";

  return RUTA_API + path;
}

function componerRutaImagenes(path) {
  const RUTA_IMAGENES = "https://www.glotoncomida.com/imagenes/locales/";

  return RUTA_IMAGENES + path;
}

export { limpiar_caracteres_especiales, componerRutaApi, componerRutaImagenes };
