import "./App.css";
import Pueblos from "./paginas/Pueblos";
import Pueblo from "./paginas/Pueblo";
import Local from "./paginas/Local";
import Tablon from "./paginas/Tablon";
import { themeOptions } from "./funciones/theme";

import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  const theme = createTheme(themeOptions);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Pueblos />} />
              <Route path="/:pueblo/:codPueblo" element={<Pueblo />} />
              <Route path="/:pueblo/:codPueblo/tablon" element={<Tablon />} />
              <Route path="/:pueblo/:local/:idLocal" element={<Local />} />
            </Routes>
          </BrowserRouter>
        </CssBaseline>
      </ThemeProvider>
    </div>
  );
}

export default App;
