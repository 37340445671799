import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { cargarLocalAsync } from "slices/local/localSlice";

import { Container } from "@mui/material";
import Stack from "@mui/material/Stack";
import Fab from "@mui/material/Fab";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";

import Header from "components/Header";
import Menu from "components/Menu";
import ModalInfo from "components/ModalInfo";
import ModalTelefono from "components/ModalTelefono";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  limpiar_caracteres_especiales,
  componerRutaImagenes,
} from "funciones/util";
import { getTitleLocal } from "funciones/titles";

export default function Local() {
  const theme = useTheme();
  let { idLocal } = useParams();

  const local = useSelector((state) => state.local.menus[idLocal]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cargarLocalAsync(idLocal));
  }, [dispatch, idLocal]);

  useEffect(() => {
    document.title = getTitleLocal(
      `${local ? local.pueblo.nombrePueblo : ""}`,
      `${local ? local.local.nombreLocal : ""}`
    );
  }, [local]);

  const [openInfo, setOpenInfo] = React.useState(false);
  const [openTelefono, setOpenTelefono] = React.useState(false);

  let breakpointDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  const clickActionInfo = () => {
    setOpenInfo(true);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const clickActionLocation = () => {
    document.location.href = `https://www.google.com/maps?q=${local.local.direccion} ${local.pueblo.nombrePueblo} ${local.pueblo.codPostal}`;

    /*  <li class="icono-nav hvr-bob">
                    <a <?php if(!esMobile()){?>target="_blank" <?php }?>href="<?php if(esMobile() && !esIphone()){?>geo:0,0?q=<?php }else{ if(esIphone()){?>https://maps.apple.com/?q=<?php }else{?>https://www.google.com/maps?q=<?php }}?><?php echo $localActual->direccion; echo ' ';echo $puebloActual->nombrePueblo; echo ' ';echo $puebloActual->codPostal;?>"><i id="local-icon" class="material-icons">place</i></a>
                </li>
                <li class="icono-nav hvr-bob">
			*/
  };

  const clickActionPhone = () => {
    setOpenTelefono(true);
  };

  const handleCloseTelefono = () => {
    setOpenTelefono(false);
  };

  return (
    <React.Fragment>
      {local && (
        <Header
          url={`/${limpiar_caracteres_especiales(local.pueblo.nombrePueblo)}/${
            local.pueblo.codPueblo
          }`}
          texto={local.local.nombreLocal}
          retorno={true}
          logo={componerRutaImagenes(
            `${local.local.icono}?v=${new Date().toDateString()}`
          )}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <IconButton onClick={clickActionInfo} aria-label="Info">
              <InfoRoundedIcon color="actions" fontSize="large" />
            </IconButton>
            <IconButton onClick={clickActionLocation} aria-label="Mapa">
              <LocationOnRoundedIcon color="actions" fontSize="large" />
            </IconButton>
            <IconButton onClick={clickActionPhone} aria-label="Teléfono">
              <PhoneRoundedIcon color="actions" fontSize="large" />
            </IconButton>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <Fab color="fab" aria-label="Info" onClick={clickActionInfo}>
              <InfoRoundedIcon color="actions" fontSize="large" />
            </Fab>

            <Fab color="fab" aria-label="Mapa" onClick={clickActionLocation}>
              <LocationOnRoundedIcon color="actions" fontSize="large" />
            </Fab>
            <Fab color="fab" aria-label="Teléfono" onClick={clickActionPhone}>
              <PhoneRoundedIcon color="actions" fontSize="large" />
            </Fab>
          </Stack>
        </Header>
      )}
      {!local && <Header url="/" retorno={true} skeletonType="local" />}
      <Container maxWidth="md" sx={{ pb: 11 }}>
        {local && (
          <React.Fragment>
            <Typography align="center" variant="h4" component="h1">
              Comida a domicilio o para llevar en {local.local.nombreLocal}
            </Typography>
            <Container maxWidth="sm" disableGutters={breakpointDownSm}>
              <Menu
                menu={local.menu}
                platos={local.platos}
                local={local.local}
              ></Menu>
            </Container>
            <ModalInfo
              open={openInfo}
              onClose={handleCloseInfo}
              local={local.local}
              pueblo={local.pueblo}
            ></ModalInfo>
            <ModalTelefono
              open={openTelefono}
              onClose={handleCloseTelefono}
              telefonos={[
                local.local.telefono,
                local.local.telefono2,
                local.local.telefono3,
              ]}
              codLocal={local.local.codLocal}
            ></ModalTelefono>
          </React.Fragment>
        )}
        {!local && (
          <React.Fragment>
            <Typography align="center" variant="h4" component="h1">
              <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
            </Typography>
            <Container maxWidth="sm" disableGutters={breakpointDownSm}>
              <Skeleton variant="rounded" height={500} />
            </Container>
          </React.Fragment>
        )}
      </Container>
    </React.Fragment>
  );
}
