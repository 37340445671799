import { createSlice } from "@reduxjs/toolkit";

import { getTablonByCodPueblo } from "services/glotonApi";

export const tablonSlice = createSlice({
  name: "tablon",
  initialState: {
    tablones: {},
  },
  reducers: {
    cargarTablon: (state, action) => {
      state.tablones[action.payload.codPueblo] = action.payload.ofertas;
    },
  },
});

// Action creators are generated for each case reducer function
export const { cargarTablon } = tablonSlice.actions;

export const esTablonSinCargar = (codPueblo) => (dispatch, getState) => {
  let stateActual = getState().tablon;
  let resultado = true;

  if (stateActual.tablones[codPueblo] !== undefined) {
    resultado = false;
  }

  return resultado;
};

export const cargarTablonAsync = (codPueblo) => (dispatch) => {
  if (dispatch(esTablonSinCargar(codPueblo))) {
    fetch(getTablonByCodPueblo(codPueblo))
      .then((response) => response.json())
      .then((tablonJSON) => {
        tablonJSON.codPueblo = codPueblo;
        dispatch(cargarTablon(tablonJSON));
      });
  }
};

export default tablonSlice.reducer;
