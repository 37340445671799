export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#FFA556",
      light: "#FFE04A",
      dark: "#E86943",
    },
    secondary: {
      main: "#FFDA8F",
      light: "#FFEAA4",
      dark: "#FF4A4F",
    },
    actions: {
      main: "#FFF",
    },
    fab: {
      main: "#E86943",
      light: "#FFA556",
      dark: "#E86943",
    },
  },
};
