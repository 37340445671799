import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { cargarPuebloAsync } from "slices/pueblo/puebloSlice";
import { cargarTablonAsync } from "slices/tablon/tablonSlice";
import { limpiar_caracteres_especiales } from "funciones/util";
import { getTitleTablon } from "funciones/titles";

import { Container } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Header from "components/Header";
import TablonContainer from "components/TablonContainer";

export default function Tablon() {
  let { codPueblo } = useParams();

  const pueblo = useSelector((state) => state.pueblo.locales[codPueblo]);
  const tablon = useSelector((state) => state.tablon.tablones[codPueblo]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cargarPuebloAsync(codPueblo));
    dispatch(cargarTablonAsync(codPueblo));
    document.title = getTitleTablon(
      `${pueblo ? pueblo.pueblo.nombrePueblo : ""}`
    );
  }, [dispatch, codPueblo]);

  useEffect(() => {
    document.title = getTitleTablon(
      `${pueblo ? pueblo.pueblo.nombrePueblo : ""}`
    );
  }, [pueblo]);

  return (
    <React.Fragment>
      <Header
        url={`/${
          pueblo
            ? limpiar_caracteres_especiales(pueblo.pueblo.nombrePueblo)
            : "pueblo"
        }/${codPueblo}`}
        texto={`Tablón ${pueblo ? pueblo.pueblo.nombrePueblo : ""}`}
        retorno={true}
      />
      <Container maxWidth="md" sx={{ pb: 11 }}>
        <Typography align="center" variant="h4" component="h1">
          {`Ofertas y novedades${
            pueblo ? " en " + pueblo.pueblo.nombrePueblo : ""
          }`}
        </Typography>
        <TablonContainer tablon={tablon}></TablonContainer>
      </Container>
    </React.Fragment>
  );
}
