import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

function Modal({ children, open, onClose, title, maxWidth }) {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth={maxWidth !== undefined}
      maxWidth={maxWidth}
    >
      {title && (
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{title}</Box>
            <Box>
              <IconButton onClick={onClose}>
                <CloseRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
      )}
      <Box>{children}</Box>
    </Dialog>
  );
}

export default Modal;
