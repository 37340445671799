import { createSlice } from "@reduxjs/toolkit";

import { getPuebloByCod } from "services/glotonApi";

export const puebloSlice = createSlice({
  name: "pueblo",
  initialState: {
    locales: {},
  },
  reducers: {
    cargarPueblo: (state, action) => {
      state.locales[action.payload.pueblo.codPueblo] = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { cargarPueblo } = puebloSlice.actions;

export const esPuebloSinCargar = (codPueblo) => (dispatch, getState) => {
  let stateActual = getState().pueblo;
  let resultado = true;

  if (stateActual.locales[codPueblo] !== undefined) {
    resultado = false;
  }

  return resultado;
};

export const cargarPuebloAsync = (codPueblo) => (dispatch) => {
  if (dispatch(esPuebloSinCargar(codPueblo))) {
    fetch(getPuebloByCod(codPueblo))
      .then((response) => response.json())
      .then((puebloJSON) => {
        dispatch(cargarPueblo(puebloJSON));
      });
  }
};

export default puebloSlice.reducer;
